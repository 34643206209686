import { mapActions } from "vuex";
import FilterMixin from "./filter";
export default {
  mixins: [FilterMixin],
  created() {
    this.setHeaders();
  },
  data: () => ({
    path: null,
    pathExport: null,
    headers: [],
    items: [],
    itemPerPage: 20,
    meta: {},
    loading: {
      list: false,
      refresh: false,
      filter: false,
      delete: false,
      export: false,
    },
    dialog: {
      form: {
        display: false,
        title: null,
        success: null,
        update: false,
      },
      filter: {
        display: false,
        title: null,
        maxWidth: 500,
      },
      show: {
        display: false,
        title: null,
        maxWidth: 500,
      },
    },
    filters: {},
    filtersFormatted: [],
    pagination: {},
    footerProps: {
      itemsPerPageOptions: [20, 50, 100],
      showFirstLastPage: true,
    },
    sortBy: ["id"],
    sortDesc: [true],
  }),
  methods: {
    setHeaders() {
      this.headers = [];
    },
    async getItems() {
      if (!this.path) return;
      let self = this;
      const qb = this.getQueryBuilder();
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      this.loading.list = true;

      qb.setPage(page).setLimit(itemsPerPage);

      if (sortBy.length > 0 && sortDesc.length > 0) {
        sortBy.forEach((s, k) => {
          qb.sortBy({ field: s, order: sortDesc[k] ? "DESC" : "ASC" });
        });
      }

      if (this.filtersFormatted.length) {
        for (const filter of this.filtersFormatted) {
          qb.setFilter(filter);
        }
      }

      try {
        const response = await this.request({
          url: `${this.path}?${qb.query()}`,
          params: self.getAdditionalParams(),
        });
        const data = response.data;

        this.items = data.data;
        this.meta = {
          total: data.total,
        };

        this.loading.list = false;
        return response;
      } catch (error) {
        this.loading.list = false;
        throw error;
      }
    },
    async refreshItems() {
      this.loading.refresh = true;
      try {
        await this.getItems();
      } catch (error) {
        // empty
      }
      this.loading.refresh = false;
    },
    filterItems() {},
    getQueryBuilder() {
      return this.$requestBuilder.create();
    },
    getAdditionalParams() {
      return {};
    },
    async applyFilter(form) {
      this.dialog.filter.display = false;
      this.filtersFormatted = this.filterApply(form);
      this.loading.filter = true;
      try {
        await this.getItems();
      } catch (error) {
        // empty
      }
      this.loading.filter = false;
    },
    async exportItems(pathExport) {
      if (!pathExport && !this.pathExport) return;
      const qb = this.getQueryBuilder();

      if (this.filtersFormatted.length) {
        for (const filter of this.filtersFormatted) {
          qb.setFilter(filter);
        }
      }

      this.loading.export = true;
      try {
        const response = await this.request({
          url: `${pathExport || this.pathExport}?${qb.query()}`,
          params: this.getAdditionalParams(),
          messages: {
            422: (error) => {
              if (error.code === "P100") {
                return this.$t("payment.no_data");
              } else if (error.code === "P101") {
                return this.$t("payment.limit_row_export_exceeded");
              }

              return this.$t("error_occured");
            },
          },
        });
        this.loading.export = false;
        return response;
      } catch (error) {
        this.loading.export = false;
        throw error;
      }
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  watch: {
    pagination: {
      handler() {
        try {
          this.getItems();
        } catch (error) {
          // empty
        }
      },
      deep: true,
    },
  },
};
